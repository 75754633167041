import { createContext, useEffect, useRef, useState } from "react"
import translations from "../i18n"
import Language from "types/language";
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import tg from "utils/tg";
import getTasks from "api/get-tasks";
import Task from "types/task";


export default () => {
    const [language, setLanguage] = useState<Language>('en');
    const [user, setUser] = useState<UserData | null>(null)
    const redicrectVideoTask = useRef<null | UserData['redirect_task_id']>(null)
    console.log("🚀 ~ redicrectVideoTask:", redicrectVideoTask)
    const [tasks, settasks] = useState<Task[] | null>(null)

    const t = <T extends keyof typeof translations['en']>(section: T): typeof translations['en'][T] => {
        return translations[language][section];
    };

    const updateTasks = async () => {
        const result = await getTasks()
        if (result) settasks(result)
        return result
    }

    const updateUserInfo = async () => {
        console.log('fetch user data');
        
        const result = await getUserData()
        if (result) setUser(result)
        if (result?.error) tg.showPopup({message:'We could not find information about you, try to send /start in the bot, and restart the app'})
    
        if(result?.redirect_task_id) redicrectVideoTask.current = result.redirect_task_id
    }

    const AppContextValue:AppContextType = {
        lang:language,
        setLanguage,
        t,
        user,
        updateUserInfo,
        setUser,tasks, settasks,updateTasks,redicrectVideoTask
    }

    useEffect(() => {
        updateUserInfo()
        updateTasks()
    }, [])
    useEffect(() => {
        if (user && user.lang && language !== user.lang) {
            setLanguage(user.lang)
        }
    }, [user])

    return {AppContext,AppContextValue}
}