import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './upgrade.css'
import getProducts from 'api/get-products'
import Product from 'types/product'
import formatNum from 'utils/format-num'
import AppContext from 'utils/app-context'
import { AnimatePresence, motion } from 'framer-motion'
import UpgradeModal from './components/UpgradeModal'
import Img from 'UI/Img'
import UpdateTimer from './components/UpdateTimer'
import Icon from 'UI/Icon'
type Props = {}

const Upgrade = (props: Props) => {
    const [produсts, setproduсts] = useState<Product[] | null>(null)
    const [showVipItems, setshowVipItems] = useState(false)
    const [selectedProduct, setselectedProduct] = useState<Product | null>(null)
    const {user,t} = useContext(AppContext)
    const text = t('upgradePage')

    const updateProducts = async () => {
        const result = await getProducts()
        if (result) setproduсts(result)
    }

    
    useEffect(() => {
        updateProducts()
    }, [])
    return (
    <PageWrapper className={showVipItems ? 'bg-2' : ''}> 
        <div className="upgrade-screen" style={{overflow: selectedProduct ? 'hidden' : 'auto'}}>
    <div className="upgrade-header">
        <h2 className="upgrade-title">{text.pageTitle}</h2>
        <div className="coin-count">
            <img src="/img/coin4.png" alt=""/>
            <div className="coin-count-textcoin-count-text">{formatNum(user?.balance || 0,true)}</div>
        </div>
    </div>
    <div className="upgrade-types">
        <div className={`standart ${showVipItems && 'type-desactive'}`} onClick={() => setshowVipItems(false)}>{text.itemTypeStandart}</div>
        <div className={`vip ${!showVipItems && 'type-desactive'}`} onClick={() => setshowVipItems(true)}>{text.itemTypeVIP}</div>
    </div>
    <div className="standart-items-container">
    <AnimatePresence mode='wait'>
            <motion.div
                key={Number(showVipItems)}
                initial={{opacity:0,translateX:50}}
                animate={{opacity:1,translateX:0}}
                transition={{duration:0.1}}
                exit={{opacity:0,translateX:-50}}
                className="standart-items"
            >
                {produсts?.filter(({category}) => {
                     if (!showVipItems && category !== 1) return null
                     else if (showVipItems && category !== 2) return null
                     return true
                }).length === 0 && <p>There are no more upgrades</p>}
                {produсts ? produсts.map((product) => {
                    const {price_stars,price_ton,price_coins,title,image,category,id} = product
                    if (!showVipItems && category !== 1) return null
                    else if (showVipItems && category !== 2) return null
                    
                    const isAvailable = (user?.balance || 0) >= price_coins
                    return (
                        <div key={id} className="standart-item open-standart-item standart-ex-item" onClick={() => setselectedProduct(product)}>
                        <div className="standart-item-icon">
                            <Img src={`/img/avatars/${image}`} size={66}/>
                            <UpdateTimer initialSeconds={product.seconds}/>
                        </div>
                        <div className="standart-item-title">{title}</div>
                        {
                            (!!price_coins && (!price_stars && !price_ton)) &&
                            <div className="standart-item-cost" style={{opacity:isAvailable ? 1 : 0.5}}>
                                {isAvailable
                                    ? <div className="standart-image"></div>
                                    : <img src="/img/lock.svg" alt="" />
                                }
                                <div className="coin-count-text">{formatNum(price_coins)}</div>
                            </div>    
                        }
                            {/* {!!price_stars && <div className="star">
                                <Img src="/img/star.svg" size={25}/>
                                <div className="star-text">{formatNum(price_stars)}</div>
                            </div>} */}
                            {!!price_ton &&<div className="rubin">
                                <Img src="/img/rubin.svg" size={25}/>
                                <div className="rubin-text">{price_ton}</div>
                            </div>}
                        </div>
                    )}) : <div style={{display:'flex',justifyContent:'center',width:'100%'}}><Icon icon='loading'/></div>}
       
            </motion.div>
    </AnimatePresence>
    </div>
    </div>
    <UpgradeModal product={selectedProduct} setProduct={setselectedProduct} refreshList={updateProducts}/>


</PageWrapper>
  )
}

export default Upgrade