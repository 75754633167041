import { motion } from "framer-motion";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Task from "types/task";
import Icon from "UI/Icon";
import AppContext from "utils/app-context";
import { MainContext } from "App";
import tg from "utils/tg";

type Props = {
  opened: boolean;
  handleQuestion: (answer: boolean) => void;
  loading: boolean;
  answer: boolean | null;
  setloading: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  task: Task;
};

const VideoTaskQuestion = ({
  opened,
  handleQuestion,
  loading,
  answer,
  setloading,
  disabled,
  task
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [bottom, setBottom] = useState(0);
  const {user,t} = useContext(AppContext)
  const {setNotification} = useContext(MainContext)
  const text = t('videoTasks')
  useEffect(() => {
    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      setBottom(height - 35);
    }
  }, []);
  const handleAnswer = (a: boolean) => {
    if (disabled || !user) return;
    if(user?.balance < task.bid_min) {
      setNotification({text:text.notEnogthCoins})
      return
    }
    handleQuestion(a);
    setloading(true);
  };
  const TelegramShare = () => {
    const tg_id = tg.initDataUnsafe.user?.id;
    const shareLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) + `_${task.id}`
  
      const shareText = `**${task.title}**\n__Watch the short video and express your opinion!__`
  
      tg.openTelegramLink(`https://t.me/share/url?url=${shareLink}&text=${encodeURIComponent(shareText)}`)
  }
  const summ = Number(task.bids_n) + Number(task.bids_y)
  const percentY = Math.round((Number(task.bids_y) / summ) * 1000) / 10
  const percentN = Math.round((Number(task.bids_n) / summ) * 1000) / 10
  return (
    // <motion.div
    //   animate={{
    //     height: opened ? 35 : 0,
    //     position:'absolute',
    //     bottom:88,
    //     left:0,
    //     width:'100%'
    //   }}
    //   className="video-task-question-container"
    // >
    //   <motion.div
    //     initial={{ y: opened ? 0 : "100%" }}
    //     animate={{ y: opened ? 0 : "100%" }}
    //     className="video-task-question"
    //   >
        <motion.div
          drag="y"
          ref={ref}
          initial={false}
          onPointerDown={(e) => e.stopPropagation()}
          animate={{ y: opened ? 0 : '100%' }}
          dragConstraints={{ top: 0, bottom: bottom }}
          className="video-task-question-body"
        >
          <hr />
          <button className="video-task-info-hint" onClick={TelegramShare}>
            <Icon icon="share" />
          </button>
          <p dangerouslySetInnerHTML={{__html:task.question}}></p>
          <div
            className={`video-task-question-buttons ${disabled && "disabled"}`}
          >{
            <>
            <button onClick={() => handleAnswer(true)}>
              {answer && loading
                ? <Icon icon="loading" />
                : <span  className={percentN < percentY ? 'bigger' : ''}><span style={{color:(task.answer && task.answer !== null) ? 'red' : ''}}>Y</span>{task.status === 1 && <span> &nbsp;{`${percentY}%`}</span>}</span>
              }
            </button>
            <button onClick={() => handleAnswer(false)}>
              {!answer && loading
                ? <Icon icon="loading" />
                : <span className={percentN > percentY ? 'bigger' : ''}><span style={{color:(!task.answer && task.answer !== null) ? 'red' : ''}}>N</span>{task.status === 1 && <span> &nbsp;{`${percentN}%`}</span>}</span>
              }
            </button>
            </>
          }
          </div>
        </motion.div>
    //   </motion.div>
    // </motion.div>
  );
};

export default VideoTaskQuestion;
