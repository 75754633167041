import UserData from "types/user-data";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
interface Responce {
  result: boolean;
  data: Data;
}

interface Data {
  lang: string;
  country: string;
  username: string;
}
export default async ({country,lang,username,wallet_address}:UserData):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "profile-edit";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("lang", String(lang));
    API_URL.searchParams.set("country", String(country));
    API_URL.searchParams.set("username", String(username));
    API_URL.searchParams.set("wallet_address", String(wallet_address || null));


    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
