import Country from "types/country";

export default async ():Promise<Country[] | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "countries";

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
